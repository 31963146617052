import React, { Suspense, lazy } from 'react';
import { AuthProvider } from 'oidc-react';
import LoadingSpinner from './components/LoadingSpinner';

const AppContent = lazy(() => import('./AppContent'));

const oidcConfig = {
    authority: process.env.REACT_APP__ZITADEL_DOMAIN,
    clientId: process.env.REACT_APP__ZITADEL_CLIENT_ID,
    redirectUri: process.env.REACT_APP__ZITADEL_REDIRECT_URI,
    responseType: 'code',
    scope: 'openid profile email urn:zitadel:iam:org:project:id:zitadel:aud urn:zitadel:iam:user:resourceowner',
    automaticSilentRenew: true,
    loadUserInfo: true,
};

function App() {
    return (
        <AuthProvider {...oidcConfig}>
            <Suspense fallback={<LoadingSpinner />}>
                <AppContent />
            </Suspense>
        </AuthProvider>
    );
}

export default App;
