import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#000',
    },
    text: {
        color: '#ca86f1',
    },
}));

const LoadingSpinner = () => {
    const classes = useStyles();
    const [loadingText, setLoadingText] = useState('Loading ...');

    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                // Redirect to the external URL
                window.location.href = 'https://lemongym.mapular.com/';
            } catch (error) {
                // If redirection fails, show the extended loading message
                setLoadingText('It seems like the page is taking longer to load. Please go back to <a href="https://lemongym.mapular.com/"> the homepage </a>');
            }
        }, 10000);
    
        // Clear the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={classes.loadingContainer}>
            <Typography variant="h5" align="center" className={classes.text}>
                <span dangerouslySetInnerHTML={{ __html: loadingText }} />
            </Typography>
        </div>
    );
};

export default LoadingSpinner;
