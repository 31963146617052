import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ca86f1',
        },
        // secondary: {
        //     main: '#19857b',
        // },
        // error: {
        //     main: red.A400,
        // },
        // black: {
        //     default: '#000',
        // },
        // background: {
        //     default: '#fff',
        // },
        white: '#fff',
    },
    typography: {
        fontFamily: 'Urbanist, Arial, sans-serif',
    },
});

export default theme;

// typography: {
//     h1: {
//         fontSize: '5rem',
//         fontWeight: 400,
//     },
//     h2: {
//         fontSize: '3.75rem',
//         fontWeight: 400,
//     },
//     h3: {
//         fontSize: '2.5rem',
//         fontWeight: 400,
//     },
//     h4: {
//         fontSize: '1.875rem',
//     },
//     subtitle1: {
//         fontSize: '1rem',
//     },
//     subtitle2: {
//         fontSize: '0.875rem',
//     },
//     body1: {
//         fontSize: '1.125rem',
//         lineHeight: '1.625',
//     },
// },
